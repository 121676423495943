var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.data.id,"title":_vm.data.title,"ok-title":"Simpan","cancel-title":"Batal","title-tag":"h4","cancel-variant":"outline-danger","ok-variant":"info","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('validation-observer',{ref:"userForm"},[_c('b-form',{attrs:{"action":"#","method":"post"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Nama Lengkap"},model:{value:(_vm.data_user.name),callback:function ($$v) {_vm.$set(_vm.data_user, "name", $$v)},expression:"data_user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Username"},model:{value:(_vm.data_user.username),callback:function ($$v) {_vm.$set(_vm.data_user, "username", $$v)},expression:"data_user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null,"placeholder":"Password"},model:{value:(_vm.data_user.password),callback:function ($$v) {_vm.$set(_vm.data_user, "password", $$v)},expression:"data_user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"password","placeholder":"Ulangi Password"},model:{value:(_vm.data_user.confirm_password),callback:function ($$v) {_vm.$set(_vm.data_user, "confirm_password", $$v)},expression:"data_user.confirm_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email","placeholder":"Email"},model:{value:(_vm.data_user.email),callback:function ($$v) {_vm.$set(_vm.data_user, "email", $$v)},expression:"data_user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"No Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":"No. Telepon"},model:{value:(_vm.data_user.no_telp),callback:function ($$v) {_vm.$set(_vm.data_user, "no_telp", $$v)},expression:"data_user.no_telp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Jenis Akun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.account_options,"placeholder":"Pilih Jenis Akun"},model:{value:(_vm.data_user.account_type),callback:function ($$v) {_vm.$set(_vm.data_user, "account_type", $$v)},expression:"data_user.account_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }