<template>
  <b-modal
    id="change-password"
    title="Ubah Password"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="changePasswordForm">
      <b-form>
        <!-- PASSWORD -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <b-form-input
              v-model="password"
              type="password"
              placeholder="Password Lama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- NEW PASSWORD -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Password Baru"
            rules="required"
          >
            <b-form-input
              v-model="new_password"
              type="password"
              placeholder="Password Baru"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- CONFIRM NEW PASSWORD -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Confirm Password Baru"
            rules="required|confirmed:Password Baru"
          >
            <b-form-input
              v-model="confirm_new_password"
              type="password"
              placeholder="Ulangi Password Baru"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { BModal, BForm, BFormInput, BFormGroup, VBModal } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed, password } from "@validations";

export default {
  name: "changePasswordModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      password: null,
      new_password: null,
      confirm_new_password: null,
      required,
      confirmed,
      password,
    };
  },

  methods: {
    validationForm() {
      this.$refs.changePasswordForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide("change-password");
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>