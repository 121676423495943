<template>
  <div>
    <b-table
      hover
      responsive
      :per-page="per_page"
      :current-page="current_page"
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      show-empty
    >
      <!-- CUSTOM SHOW EMPTY DATA -->
      <template #empty="scope">
        <center>
          <h6>{{ scope.emptyText }}</h6>
        </center>
      </template>

      <!-- CUSTOM SHOW EMPTY FILTERED -->
      <template #emptyfiltered="scope">
        <center>
          <h6>{{ scope.emptyFilteredText }}</h6>
        </center>
      </template>

      <!-- CUSTOM CELL NO -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- CUSTOM CELL ACTION -->
      <template #cell(action)="data">
        <!-- EDIT AKSES BUTTON -->
        <b-button
          variant="outline-warning"
          v-b-tooltip.hover.v-dark="'Hak Akses'"
          class="btn-icon mr-50"
          size="sm"
          @click="addAksesUser(data.item.id)"
        >
          <feather-icon icon="SettingsIcon" />
        </b-button>

        <!-- CHANGE PASSWORD BUTTON -->
        <b-button
          variant="outline-primary"
          v-b-tooltip.hover.v-dark="'Ubah Password'"
          class="btn-icon mr-50"
          size="sm"
          @click="changePassword(data.item.id)"
        >
          <feather-icon icon="KeyIcon" />
        </b-button>

        <!-- EDIT BUTTON -->
        <b-button
          variant="outline-info"
          class="btn-icon mr-50"
          v-b-tooltip.hover.v-dark="'Edit'"
          size="sm"
          @click="editUser(data.item.id)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>

        <!-- DELETE BUTTON -->
        <b-button
          variant="outline-danger"
          v-b-tooltip.hover.v-dark="'Hapus'"
          class="btn-icon"
          size="sm"
          @click="deleteUser(data.item.id)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
    </b-table>

    <!-- ROW INFORMATION AND SELECT ROW -->
    <div class="d-flex mt-2">
      <div class="d-flex align-items-center mr-auto ml-2">
        <div>Showing {{ current_page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select v-model="per_page" :options="page_options" />
        </div>
        <div>
          of
          {{ data.items.length }} Entries
        </div>
      </div>

      <!-- PAGINATION -->
      <div class="ml-auto mt-1 mr-2">
        <b-pagination
          v-model="current_page"
          :total-rows="data.items.length"
          :per-page="per_page"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "userTable",
  components: {
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    VBTooltip,
  },

  props: {
    data: Object,
    editUser: Function,
    changePassword: Function,
    addAksesUser: Function,
    deleteUser: Function,
  },

  data() {
    return {
      per_page: this.data.row,
      page_options: [3, 5, 10],
      current_page: 1,
    };
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
 