<template>
  <!-- DAFTAR USER -->
  <cardDaftarUser />
</template>
<script>
import cardDaftarUser from "@/components/setting/user/cardDaftarUser.vue";
export default {
  name: "User",
  components: {
    cardDaftarUser,
  },

  data() {
    return {
      title: "User | Online Media Monitoring",
    };
  },
  
  created() {
    document.title = this.title;
  },
};
</script>