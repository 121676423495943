<template>
  <b-card no-body class="h-100">
    <b-card-header>
      <b-card-title> Daftar User </b-card-title>
      <!-- HELP ICON -->
      <feather-icon
        icon="HelpCircleIcon"
        size="20"
        class="mr-auto ml-1"
        v-b-tooltip.hover.html="helpField"
      />

      <!-- ADD USER BUTTON -->
      <b-button variant="outline-primary" class="ml-auto mr-1" @click="addUser">
        <feather-icon icon="PlusIcon" class="mr-50" />Tambah User
      </b-button>

      <!-- FORM SEARCH -->
      <div class="w-25">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" style="color: #7367f0 !important" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Cari"
            v-model="table_data.filter"
            style="height: 39px"
          />
        </b-input-group>
      </div>
    </b-card-header>

    <!-- TABEL COMPONENT -->
    <userTable
      :data="table_data"
      :editUser="editUser"
      :changePassword="changePassword"
      :addAksesUser="addAksesUser"
      :deleteUser="deleteUser"
      class="mb-2"
    />

    <!-- ADD USER FORM MODAL COMPONENT -->
    <userFormModal :data="modal_data" :data_user="data_user" />

    <!-- EDIT USER FORM MODAL COMPONENT -->
    <userFormModal :data="edit_modal_data" :data_user="data_user" />

    <!-- EDIT HAK AKSED FORM MODAL COMPONENT -->
    <hakAksesFormModal :data="akses_modal_data" />

    <!-- CHANGE PASSWORD FORM MODAL COMPONENT -->
    <changePasswordModal :data="data_user" />
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  VBTooltip,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import userTable from "./userTable.vue";
import userFormModal from "./userFormModal.vue";
import hakAksesFormModal from "./hakAksesFormModal.vue";
import changePasswordModal from "./changePasswordModal.vue";

export default {
  name: "cardDaftarUser",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BProgress,
    BProgressBar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ToastificationContent,
    userFormModal,
    userTable,
    hakAksesFormModal,
    changePasswordModal,
  },

  data() {
    return {
      data_user: {
        name: "",
        username: "",
        password: "",
        confirm_password: "",
        email: "",
        no_telp: "",
        account_type: "",
      },
      value: 25,
      max: 100,
      modal_data: {
        title: "Tambah User",
        type: "add",
        id: "add-user",
      },
      akses_modal_data: {
        title: "Hak Akses User",
        type: "add",
        id: "add-akses-user",
      },
      edit_modal_data: {
        title: "Edit User",
        type: "edit",
        id: "edit-user",
      },
      helpField: {
        title:
          "<p align=left>Grup digunakan untuk mengelompokkan beberapa topik menjadi satu. Hanya topik media konvensional yang dapat dikelompokkan.</p><p align=left>Topik digunakan untuk mengelompokkan beberapa keyword menjadi satu</p>",
      },
      table_data: {
        type: "user",
        row: 5,
        filter: null,
        fields: [
          {
            key: "name",
            label: "Nama",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "username",
            label: "Username",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "15%" },
          },
          {
            key: "email",
            label: "Email",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "15%" },
          },
          {
            key: "no_telp",
            label: "No.Telp",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "15%" },
          },
          {
            key: "account_type",
            label: "Role",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
            thStyle: { width: "15%" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "20%" },
          },
        ],
        items: [
          {
            id: 1,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 2,
            name: "Admin Kemenkes",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 3,
            name: "Admin Kurasi",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 4,
            name: "Admin Kabayan",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 5,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 6,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 7,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 8,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 9,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
          {
            id: 10,
            name: "Admin KPP",
            username: "kpp.admin",
            password: "lalala",
            confirm_password: "lalala",
            email: "kpp@admin.com",
            no_telp: "081234567890",
            account_type: "Admin",
          },
        ],
      },
    };
  },

  methods: {
    makeToast(variant, message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Berhasil",
            icon: "CheckCircleIcon",
            text: `${message}`,
            variant,
          },
        },
        {
          timeout,
        }
      );
    },
    addUser() {
      this.data_user.name = "";
      this.data_user.username = "";
      this.data_user.password = "";
      this.data_user.confirm_password = "";
      this.data_user.email = "";
      this.data_user.no_telp = "";
      this.data_user.account_type = "";
      this.$bvModal.show("add-user");
    },
    editUser(id) {
      let data = this.table_data.items.find((el) => el.id == id);
      this.data_user.name = data.name;
      this.data_user.username = data.username;
      this.data_user.password = data.password;
      this.data_user.confirm_password = data.confirm_password;
      this.data_user.email = data.email;
      this.data_user.no_telp = data.no_telp;
      this.data_user.account_type = data.account_type;
      this.$bvModal.show("edit-user");
    },
    changePassword(id) {
      let data = this.table_data.items.find((el) => el.id == id);
      this.data_user.password = data.password;
      this.$bvModal.show("change-password");
    },
    addAksesUser(id) {
      this.$bvModal.show("add-akses-user");
    },
    deleteUser(id) {
      let data = this.table_data.items.find((el) => el.id == id);
      this.$swal({
        title: "Apakah Anda Yakin?",
        html:
          data.name +
          "<div class='text-secondary' style='font-size:12px'>Akan dihapus dari daftar user</div>",
        icon: "warning",
        customClass: "swal-wide",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Ya, Hapus !",
        focusCancel: true,
        reverseButtons: true,
        customClass: {
          cancelButton: "btn btn-danger mr-1 px-3",
          confirmButton: "btn btn-outline-info px-4",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.makeToast("success", "User Telah Dihapus", 5000);
        }
      });
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>