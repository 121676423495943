<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    @ok.prevent="validationForm()"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <validation-observer ref="userForm">
      <b-form action="#" method="post">
        <!-- FULL NAME -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Nama Lengkap"
            rules="required"
          >
            <b-form-input
              v-model="data_user.name"
              type="text"
              placeholder="Nama Lengkap"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- USERNAME -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Username"
            rules="required"
          >
            <b-form-input
              v-model="data_user.username"
              type="text"
              placeholder="Username"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- PASSWORD -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required"
          >
            <b-form-input
              v-model="data_user.password"
              type="password"
              :state="errors.length > 0 ? false : null"
              placeholder="Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- CONFIRM PASSWORD -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Password Confirm"
            rules="required|confirmed:Password"
          >
            <b-form-input
              v-model="data_user.confirm_password"
              :state="errors.length > 0 ? false : null"
              type="password"
              placeholder="Ulangi Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- EMAIL -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              v-model="data_user.email"
              :state="errors.length > 0 ? false : null"
              type="email"
              placeholder="Email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- NO TELEPON -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="No Telepon"
            rules="required"
          >
            <b-form-input
              v-model="data_user.no_telp"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder="No. Telepon"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- JENIS AKUN -->
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Jenis Akun"
            rules="required"
          >
            <v-select
              v-model="data_user.account_type"
              :options="account_options"
              placeholder="Pilih Jenis Akun"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";

export default {
  name: "userFormModal",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    VBModal,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    data: Object,
    data_user: Object,
  },

  data() {
    return {
      account_options: ["Admin", "User"],
      required,
      password,
      email,
      confirmed,
    };
  },

  methods: {
    validationForm() {
      this.$refs.userForm.validate().then((success) => {
        if (success) {
          this.$bvModal.hide(this.data.id);
        }
      });
    },
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>