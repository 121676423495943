<template>
  <b-modal
    :id="data.id"
    :title="data.title"
    ok-title="Simpan"
    cancel-title="Batal"
    title-tag="h4"
    cancel-variant="outline-danger"
    ok-variant="info"
    no-close-on-backdrop
  >
    <!-- HAK AKSES USER -->
    <b-list-group class="my-2">
      <b-list-group-item
        v-for="data in data_fitur"
        :key="data.id"
        class="d-flex align-items-center"
      >
        <div>{{ data.title }}</div>
        <div class="ml-auto">
          <b-form-checkbox
            v-model="akses_user"
            :value="data.title"
            class="custom-control-success"
          >
          </b-form-checkbox>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>
<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BButton,
  BFormCheckbox,
  VBModal,
} from "bootstrap-vue";

export default {
  name: "hakAksesFormModal",
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormCheckbox,
    VBModal,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      akses_user: [],
      data_fitur: [
        {
          id: 1,
          title: "Fitur 1",
        },
        {
          id: 2,
          title: "Fitur 2",
        },
        {
          id: 3,
          title: "Fitur 3",
        },
        {
          id: 4,
          title: "Fitur 4",
        },
        {
          id: 5,
          title: "Fitur 5",
        },
      ],
    };
  },

  directives: {
    "b-modal": VBModal,
  },
};
</script>